import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import Checkout from './Checkout';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';
import {CartContext} from './CartContext'
import { useState } from 'react';

function App() {

	const [cartItems, setCartItems] = useState([])

  return (
    <BrowserRouter>
    
      <CartContext.Provider value={{cartItems,setCartItems}}>

        <nav class="black">
          <ul>
            <li><Link to="/">首頁</Link></li>
            <li><Link to="/checkout">購物車</Link></li>
            <li><Link to="https://sims.stephanietoo.com">The Sims</Link></li>
            <li><Link to="https://blog.stephanietoo.com">Blog</Link></li>
          </ul>
        </nav>

        <button id="backToTop" className="rb4 round" title="Back to top" onClick={()=>window.scrollTo(0,0)}>·</button>

        <Routes>
          <Route path="/" element={<ProductList/>}/>
          <Route path="/checkout" element={<Checkout/>}/>

          <Route path="/product" element={<ProductDetail/>}>
            <Route path=":id" element={<ProductDetail/>}/>
          </Route>

          <Route path="*" element={<p>找不到頁面</p>}/>
        </Routes>
        
      </CartContext.Provider>
      
    </BrowserRouter>
  );
}

export default App;